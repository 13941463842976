export const timeline = [
    {
        "organisation": "NTR",
        "icon": "assets/ntr.webp",
        "functions": [
            {
                "title": "Ontwikkelaar Interactieve Media",
                "description": "Interactieve toepassingen ontwerpen, ontwikkelen & testen met gebruikers voor programma's van de NTR, zoals Het Klokhuis 🍏.",
                "start": [2021, 7, 1],
                "end": null,
            },
            {
                "title": "Stagiair",
                "description": "NPO Innovatie onderzoek naar het inzetten van WebAR voor de jeugd + onderzoek/realisering van het toegankelijker maken van websites van de NTR.",
                "start": [2021, 2, 1],
                "end": [2021, 8, 1],
            },
        ],
    },
    {
        "organisation": "Hogeschool Leiden",
        "icon": "assets/hogeschoolleiden.webp",
        "functions": [
            {
                "title": "Student-assistent Biologie en Medisch Laboratoriumonderzoek",
                "description": "Voor de ontwikkeling van een educatieve app in virtual reality om het onderwijs te verrijken.",
                "start": [2020, 10, 1],
                "end": [2021, 10, 1],
            },
        ],
    }
];