import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef } from "react";
import './Intro.sass';

const Intro = () => {
    const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
    const [isVideoStarted, setIsVideoStarted] = React.useState(false);
    const [isVideoFinished, setIsVideoFinished] = React.useState(false);
    const [shouldRenderIntro, setShouldRenderIntro] = React.useState(window.location.href.indexOf('#') === -1);
    const videoRef = useRef(null);

    useEffect(() => {
        const startVideo = () => {
            if (!isVideoStarted && videoRef.current) {
                setIsVideoStarted(true);
                videoRef.current.play();

                setTimeout(() => {
                    setIsVideoFinished(true);
                }, 7000);
            }
        };

        if (!isVideoLoaded && videoRef.current) {
            ['canplay', 'play', 'onloadeddata', 'onloadstart'].forEach(event => videoRef.current.addEventListener(event, startVideo));
            videoRef.current.innerHTML = '<source src="assets/recording.webm" type="video/webm"><source src="assets/recording.mp4" type="video/mp4">';
            setIsVideoLoaded(true);
        }
    }, [isVideoLoaded, isVideoStarted, videoRef]);

    useEffect(() => {
        if (isVideoFinished) setTimeout(() => {
            setShouldRenderIntro(false);
        }, 500);
    }, [isVideoFinished]);

    return !shouldRenderIntro ? <></> : <FocusTrap>
        <div className={'intro absolute z-50 md:p-12 w-full h-full bg-black transition-all duration-500 flex place-items-center items-center ' + (isVideoFinished ? 'opacity-0' : 'opacity-100')} onClick={() => setIsVideoFinished(true)}>
            <video
                ref={videoRef}
                autoPlay
                muted
                preload="auto"
                tabIndex={0}
                title="Intro video"
                className={'intro__video md:rounded-4xl overflow-hidden focus:outline-none m-auto max-h-full w-screen h-screen object-cover md:h-auto md:w-auto lg:w-auto transition-all ' + (isVideoFinished ? 'transform scale-125 opacity-0' : '')}
            >
            </video>
            <button className="absolute py-1 px-4 bg-white z-30 opacity-0 rounded-full bottom-12 left-1/2 transform -translate-x-1/2 border-2 focus:opacity-100 scale-0 focus:scale-100 transition-all focus:shadow-lg focus:outline-black" onClick={() => setShouldRenderIntro(false)}>Sluit intro video</button>
            {isVideoStarted && <div className="intro__circle opacity-0 md:opacity-100"/>}
        </div>
    </FocusTrap>;
}

export default Intro;