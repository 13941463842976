import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import Logo from "components/content/menu/logo/Logo";
import ColorThief from "colorthief";
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight, mdiGestureTapHold, mdiClose, mdiOpenInNew } from '@mdi/js';
import { rgbToHex, getForegroundColor } from "../../utils/color";
import useIsOnScreen from "hooks/useIsOnScreen";

import './Card.sass';

const Card = ({
    onRefReady = undefined,
    title = undefined,
    subtitle = undefined,
    description = undefined,
    grade = undefined,
    icon = undefined,
    images = undefined,
    index = undefined,
    links = [],
    type = undefined,
    tags = [],
    date = [0, 1, 1],
    children = undefined,
    filterTags = [],
    showImages = true,
    onFilterTagClicked = undefined,
}) => {
    const url = '#project=' + title.toLowerCase().replaceAll(' ', '-');

    const [showDetails, setShowDetails] = React.useState(window.location.href.indexOf(url) > -1);
    const tileRef = React.useRef(null);

    const isOnScreen = useIsOnScreen(tileRef);

    useEffect(() => {
        onRefReady(tileRef.current);
    }, [onRefReady, tileRef]);

    window.onpopstate = () => {
        if (window.location.href.indexOf(url) < 0 && showDetails) setShowDetails(false);
        else if (window.location.href.indexOf(url) > 1) setShowDetails(true);
    };

    return <>
        <a
            key={index + title}
            className={'card lg:max-w-xl break-inside overflow-visible block transition-all opacity-100 duration-300 card--' + type + (images ? ' card--images -mb-4 mt-4 transform translate-y-6' : '') + (links.length > 0 ? ' card--link' : '')}
            href={url}
            aria-label={title + '. Klik om details te openen. Beschrijving: ' + description}
            ref={tileRef}
            onClick={() => setShowDetails(true)}
        >
            <article
                className={'overflow-visible bg-white dark:bg-gray-800 dark:text-white shadow-2xl transition-all md:rounded-2xl md:max-w-xl mx-auto lg:mx-0 p-4 lg:p-6 flex flex-col md:flex-row transform md:hover:bg-primary md:hover:text-white motion-safe:md:hover:scale-105 md:active:scale-95'}>

                {(icon && !images) && <img loading="lazy" className={'card__icon aspect-square block bg-primary transition-all rounded-full h-24 w-24 min-w-24 m-2 mr-4 object-cover transform duration-500 ' + (showImages ? 'scale-100' : 'scale-0')} src={showImages ? icon : undefined} alt="" />}

                <div className="w-full relative select-none">
                    {(icon && images) && <img loading="lazy" className={'card__icon card__icon--mini absolute z-10 transition-all duration-500 rounded-full h-16 w-16 mr-2 object-cover inline-block right-2 -top-6 float-right shadow-md transform ' + (showImages ? 'scale-100' : 'scale-0')} src={(isOnScreen && showImages) ? icon : undefined} alt="" />}

                    {images && <CardImages index={index} images={images} showImages={showImages} isOnScreen={isOnScreen} />}

                    {title &&
                        <h1 className={'card__title font-bold text-3xl pb-3 font-outfit ' + ((!icon && !images) ? '' : 'pt-4')}>
                            {title}
                        </h1>
                    }

                    {description && <p className="card__description mb-4">{description}{grade && ` Beoordeeld met een ${grade}.`}</p>}

                    {type === 'interactive' && links.length > 0 && <div className="w-full relative aspect-square">
                        <div className="card--interactive__badge absolute z-10 top-0 -right-2 transition-all shadow-md rounded-full h-16 w-16 mr-2 bg-black bg-opacity-20 dark:bg-transparent text-white dark:text-gray-800 flex flex-row justify-center items-center">
                            <Icon path={mdiGestureTapHold} size={1.5} />
                        </div>
                        <iframe loading="lazy" src={isOnScreen ? links[0]['url'] : 'about:blank'} tabIndex={-1} className="card__iframe absolute top-0 mb-4 bg-white h-96 w-full rounded-2xl" title={title} />
                    </div>
                    }

                    {tags && <ul className="pt-2 flex flex-row flex-wrap items-start gap-2">
                        <li className={'card__tag lowercase border-transparent-150 dark:border-white dark:border-opacity-10 py-1 px-4 rounded-full inline-block transition-all ' + (filterTags.includes(date[0].toString()) ? 'bg-primary text-white border-primary' : '')}>{date[0]}</li>
                        {tags.slice(0, filterTags.length > 0 ? tags.length : 2).map((tag, i) => {
                            return <li key={i} className={'card__tag border-transparent-150 dark:border-white dark:border-opacity-10 lowercase py-1 px-4 rounded-full inline-block transition-all ' + (filterTags.includes(tag) ? 'bg-primary text-white border-primary' : '')}>{tag}</li>;
                        })}

                    </ul>}
                </div>

                {children}
            </article>
        </a>
        {showDetails && <CardDetails
            title={title}
            subtitle={subtitle}
            description={description}
            grade={grade}
            images={images}
            links={links}
            icon={icon}
            type={type}
            tags={tags}
            filterTags={filterTags}
            date={date}
            hideDetails={() => {
                setShowDetails(false);
                window.history.back();
                window.history.pushState({}, document.title, "/");
            }}
            onFilterTagClicked={(tag) => {
                onFilterTagClicked(tag);
            }} />}
    </>;
};

export default Card;

const CardImages = ({ index, images, showImages, isOnScreen }) => {
    const [visibleImageIndex, setVisibleImageIndex] = useState(0);
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            setTimeout(() => {
                if (isMounted.current && !window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                    if (images.length < 2) return;
                    if (visibleImageIndex < images.length - 1) {
                        setVisibleImageIndex(visibleImageIndex + 1);
                    } else {
                        setVisibleImageIndex(0);
                    }
                }
            }, 7500 + (200 * index));
        }
    }, [visibleImageIndex, images.length, index]);

    return <div
        className="card__images max-w-xl mx-auto bg-primary relative rounded-2xl overflow-hidden shadow-xl transform -translate-y-12 -mb-10 transition-all duration-300"
        style={{
            aspectRatio: '3/2',
        }}>
        {showImages && images.map((image, i) => {
            return <img
                    loading="lazy"
                    key={index + " " + i}
                    className={'card__images__image absolute w-full h-full rounded-2xl object-cover transition-all duration-1000 transform ' + (visibleImageIndex === i ? 'opacity-100 scale-100 z-10' : 'opacity-0 scale-150')}
                    src={isOnScreen ? image : ''}
                    alt=""
                    style={{
                        imageRendering: 'auto',
                    }}
                />;
        })}
    </div>;
};

const CardImagesBanner = ({ images, onImageChanged }) => {
    const [visibleImageIndex, setVisibleImageIndex] = useState(0);
    const scrollRef = useRef(null);
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isMounted.current && !window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                if (images.length < 2) return;
                if (visibleImageIndex < images.length - 1) {
                    setVisibleImageIndex(visibleImageIndex + 1);
                } else {
                    setVisibleImageIndex(0);
                }
            }
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [visibleImageIndex, images.length]);

    useLayoutEffect(() => {
        scrollRef.current.scrollLeft = scrollRef.current.offsetWidth * visibleImageIndex;
        if (scrollRef.current.childNodes[visibleImageIndex].complete && scrollRef.current.childNodes[visibleImageIndex].naturalHeight !== 0) onImageChanged(scrollRef.current.childNodes[visibleImageIndex]);
    }, [visibleImageIndex, onImageChanged]);

    return <div
        className="card__images--banner w-full bg-primary relative overflow-hidden transition-all duration-300 md:rounded-t-2xl 2xl:rounded-tr-none shadow-md"
        style={{
            aspectRatio: '3/2',
        }}>
        <div
            className="flex flex-row snap-mandatory snap-x w-full h-full overflow-y-hidden overflow-x-scroll scroll-smooth no-scrollbar transition-all pointer-events-none md:rounded-t-2xl 2xl:rounded-tr-none"
            ref={scrollRef}>
            {images.map((image, i) => {
                return <img
                    loading="lazy"
                    key={i}
                    className={'card__images__image snap-center w-screen block object-cover transition-all duration-1000 transform select-none pointer-events-none'}
                    src={image}
                    alt=""
                    style={{
                        imageRendering: 'auto',
                        aspectRatio: '3/2',
                    }}
                />;
            })}
        </div>
        <div className="absolute bg-gradient-to-b from-black opacity-40 to-transparent h-28 w-full top-0 md:hidden z-40" />
        {images.length > 1 && <>
            <button
                className="absolute top-1/2 transition-all z-40 bg-black bg-opacity-50 text-white w-12 h-12 text-2xl shadow-md hidden lg:block left-4 rounded-full transform -translate-y-1/2 hover:scale-125 active:scale-100 opacity-90 hover:opacity-100 backdrop-filter lg:backdrop-blur-md"
                aria-label="Vorige afbeelding"
                onClick={() => {
                    if (visibleImageIndex !== 0) {
                        setVisibleImageIndex(visibleImageIndex - 1);
                    } else {
                        setVisibleImageIndex(images.length - 1);
                    }
                }}>
                <Icon path={mdiArrowLeft} size={1.2} className="m-auto" />
            </button>
            <button
                className="absolute top-1/2 right-4 transition-all z-40 bg-black bg-opacity-50 text-white w-12 h-12 text-2xl shadow-md hidden lg:block rounded-full transform -translate-y-1/2 hover:scale-125 active:scale-100 opacity-90 hover:opacity-100 backdrop-filter lg:backdrop-blur-md"
                aria-label="Volgende afbeelding"
                onClick={() => {
                    if (visibleImageIndex < images.length - 1) {
                        setVisibleImageIndex(visibleImageIndex + 1);
                    } else {
                        setVisibleImageIndex(0);
                    }
                }}>
                <Icon path={mdiArrowRight} size={1.2} className="m-auto" />
            </button>
        </>}
        {images.length > 1 && <div className="absolute transition-all transfrom origin-bottom md:hover:scale-150 bottom-4 z-40 flex flex-row items-center justify-center left-1/2 transform -translate-x-1/2 p-1 rounded-full bg-black bg-opacity-20 hover:bg-opacity-50 shadow-sm backdrop-filter lg:backdrop-blur-md">
            {images.map((image, i) => <div key={image} className={'p-2 md:p-1 transform transition-all cursor-pointer ' + (visibleImageIndex === i ? 'scale-125 opacity-100 hover:scale-150' : 'opacity-60 hover:scale-125')} onClick={() => setVisibleImageIndex(i)}>
                <div className="h-3 w-3 md:h-2 md:w-2 bg-white rounded-full transition-all transform active:scale-75" />
            </div>)}
        </div>}
    </div>;
};

const CardDetails = ({
    title = undefined,
    subtitle = undefined,
    description = undefined,
    grade = undefined,
    icon = undefined,
    images = undefined,
    links = [],
    type = undefined,
    tags = [],
    date = [0, 1, 1],
    filterTags = [],
    hideDetails = undefined,
    onFilterTagClicked = undefined,
}) => {
    const [isClosing, setIsClosing] = useState(false);
    const [accentColor, setAccentColor] = useState('undefined');
    const [imageUrl, setImageUrl] = useState('undefined');

    // @ts-ignore
    const colorThief = new ColorThief();

    useEffect(() => {
        if (isClosing) setTimeout(hideDetails, 300);
    }, [isClosing, hideDetails]);

    return <FocusTrap>
        <article
            className="card__details fixed inset-0 bg-gray-900 dark:bg-gray-700 z-40 transition-all motion-reduce:bg-opacity-90 bg-opacity-40 2xl:bg-opacity-50 overflow-hidden md:overflow-y-auto md:py-16 md:flex flex-col content-center backdrop-filter lg:backdrop-blur-md"
            style={{
                opacity: isClosing ? 0 : 1,
            }}>
            <button className="card__details__background absolute w-full h-full inset-0 cursor-default transition-all" tabIndex={-1} onClick={() => setIsClosing(true)} />
            <Logo className="card__details__logo mx-auto mb-8 hidden 2xl:block 2xl:text-6xl z-50" tabIndex={-1} onClick={() => setIsClosing(true)} />
            <div
                className="card__details__content transition-all relative w-full max-w-xl 2xl:max-w-6xl bg-white dark:bg-gray-800 dark:text-white mx-auto shadow-2xl md:rounded-2xl h-screen md:h-auto"
                style={{
                    transform: isClosing ? 'translateY(100%) !important' : undefined,
                }}>
                <button
                    className="absolute transition-all z-50 bg-white text-black w-12 h-12 text-2xl border-2 md:border-0 shadow-md block left-4 md:left-auto md:-right-24 md:mr-2 mt-4 rounded-full transform md:-translate-x-1/2 hover:scale-125 active:scale-100"
                    aria-label="Sluit details"
                    onClick={() => setIsClosing(true)}
                    title="Sluit venster">
                    <Icon path={mdiClose} size={1.2} className="hidden md:block m-auto" />
                    <Icon path={mdiArrowLeft} size={1.2} className="md:hidden m-auto" />
                </button>
                {links.length > 0 && <button
                    className="absolute transition-all z-50 bg-primary text-white text-center hidden lg:block w-12 h-12 mt-20 text-2xl shadow-md -right-18 mr-2 rounded-full transform hover:scale-125 active:scale-100"
                    aria-label={links[0]['title']}
                    onClick={() => window.open(links[0]['url'], '_blank', 'noreferrer noopener')}
                    title={links[0]['url']}>
                    <Icon path={mdiOpenInNew} size={1.2} className="m-auto" />
                </button>}
                <div className="h-full overflow-auto md:overflow-visible relative 2xl:flex flex-row place-items-stretch justify-items-stretch">
                    <div className="2xl:flex-1 2xl:flex 2xl:flex-col 2xl:max-w-xl">
                        {(icon && !images) && <div className="bg-primary w-full p-12 md:rounded-t-2xl 2xl:rounded-tr-none">
                            <img loading="lazy" className="card__icon rounded-full h-24 w-24 object-cover mx-auto" src={icon} alt="" />
                        </div>}
                        {images && <CardImagesBanner images={images} onImageChanged={(image) => {
                            let pickedColor = undefined;
                            const palette = colorThief.getPalette(image);

                            palette.forEach((color, i) => {
                                if (!pickedColor) {
                                    if (getForegroundColor(color) === 'white') {
                                        pickedColor = color;
                                    }
                                    if (i === palette.length - 1) pickedColor = palette[0];
                                }
                            });
                            setAccentColor(rgbToHex(pickedColor));
                            setImageUrl(image.src);
                        }} />}

                        {type === 'interactive' && links.length > 0 && <iframe loading="lazy" src={links[0]['url']} className="card__iframe aspect-square bg-white w-full md:rounded-t-2xl border-b-2 2xl:border-r-2 2xl:rounded-tr-none" title={title} />}

                        <div className="relative p-6 md:p-8 mb-4 md:mb-8 2xl:mb-0 bg-primary text-white shadow-md transition-all duration-500 flex-grow 2xl:rounded-bl-2xl"
                            style={{
                                background: accentColor,
                                color: accentColor ? getForegroundColor(accentColor) : undefined,
                            }}>
                            {imageUrl && <img className="h-full w-full object-cover absolute inset-0 opacity-20 filter blur-md transition-all" src={imageUrl} alt="" />}
                            {title &&
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a className={'card__title font-bold text-4xl md:text-5xl font-outfit lg:text-6xl lg:font-extrabold ' + ((images && accentColor) ? (getForegroundColor(accentColor) === 'black' ? 'opacity-70' : 'opacity-90') : '')} tabIndex={-1} href={links.length > 0 ? links[0]['url'] : undefined} rel="noopener noreferrer" target="_blank">
                                    {title}
                                </a>
                            }

                            {subtitle &&
                                <p className={'card__subtitle opacity-60 -mt-2 pt-3 font-outfit'}>
                                    {subtitle}
                                </p>
                            }
                        </div>
                    </div>

                    <div className="px-4 md:px-8 pb-0 md:pb-0 2xl:flex-1 2xl:pt-8 2xl:flex 2xl:flex-col 2xl:max-w-xl">
                        {description && <>
                            <p className="hidden 2xl:block font-bold text-primary">Beschrijving</p>
                            <p className="card__description mb-4 md:mb-6 2xl:mb-8">{description}</p>
                            <div className="2xl:mb-8 2xl:border-b-2 -mx-4 md:-mx-8 border-black dark:border-white border-opacity-10" />
                        </>}

                        {grade && <>
                            <div className="card__details__grade flex flex-row mt-8 md:mt-0 mb-8 bg-gray-100 dark:bg-white dark:bg-opacity-5 shadow-md 2xl:shadow-none 2xl:bg-transparent overflow-hidden 2xl:overflow-visible rounded-3xl 2xl:rounded-none pr-4 2xl:pr-0">
                                <div className="flex-grow p-4 pt-3 2xl:p-0 2xl:pr-8">
                                    <p className="font-bold text-primary">Beoordeling</p>
                                    <p className="card__description">Beoordeeld met {grade} van de 10 punten door mijn opleiding informatica interactie-technologie.</p>
                                </div>
                                <div className="card__details__grade__score flex flex-shrink-0 items-center justify-center min-w-16 w-16 h-16 text-white text-2xl font-bold self-center select-none transform"><span className="block transform">{grade}</span></div>
                            </div>
                            <div className="hidden 2xl:block mb-8 border-b-2 -mx-8 border-black dark:border-white border-opacity-10" />
                        </>}

                        <nav className="flex flex-col gap-4 select-none flex-grow">
                            {links.map((link, index) => <button
                                key={index}
                                className={(index > 0 ? 'bg-white dark:bg-opacity-10 text-primary dark:text-primary-300 border-2 hover:border-primary dark:border-opacity-30 hover:shadow-sm' : 'bg-primary text-white hover:shadow-lg') + ' px-6 py-2 rounded-full text-lg transition-all transform active:scale-95'}
                                onClick={() => window.open(link['url'], '_blank', 'noreferrer noopener')}
                                title={link['url']}>
                                {link['title']} <Icon path={mdiOpenInNew} size={0.8} className="m-auto inline-block -mt-0.5" />
                            </button>)}
                        </nav>

                        {tags && <nav className="p-4 md:p-8 mt-8 bg-gray-100 dark:bg-gray-900 dark:text-white border-t-2 border-black dark:border-white border-opacity-10 -mx-4 md:-mx-8 flex flex-row flex-wrap items-start gap-2 select-none md:rounded-b-2xl 2xl:rounded-bl-none">
                            <li
                                role="button"
                                onClick={() => {
                                    hideDetails();
                                    onFilterTagClicked(date[0]);
                                }}
                                tabIndex={0}
                                aria-label={'Projecten uit "' + date[0] + '" laten zien'}
                                className={'card__tag lowercase py-1 px-4 rounded-full inline-block transition-all hover:shadow-md transform hover:scale-105 active:scale-90 hover:bg-black border-opacity-15 border-black dark:border-white ' + (filterTags.includes(date[0].toString()) ? 'bg-primary text-white border-primary' : 'hover:text-white')}>
                                {date[0]}
                            </li>
                            {tags.map((tag, i) => <li
                                key={i}
                                role="button"
                                onClick={() => {
                                    hideDetails();
                                    onFilterTagClicked(tag);
                                }}
                                tabIndex={0}
                                aria-label={'Tag "' + tag + '" zoeken in mijn projecten'}
                                className={'card__tag lowercase py-1 px-4 rounded-full inline-block transition-all hover:shadow-md transform hover:scale-105 active:scale-90 hover:bg-black border-opacity-15 border-black dark:border-white ' + (filterTags.includes(tag) ? 'bg-primary text-white border-primary' : 'hover:text-white')}>
                                {tag}
                            </li>
                            )}
                        </nav>}
                    </div>
                </div>
            </div>
        </article>
    </FocusTrap>;
};