import fontColorContrast from 'font-color-contrast'

export const rgbToHex = (rgb) => '#' + rgb.map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('');

export const hexToRgb = (color) => color.match(/\w\w/g).map(x=>+`0x${x}`);

export const getForegroundColor = (backgroundHex) => {
    if (backgroundHex.toString().indexOf('#') !== -1) {
        backgroundHex = hexToRgb(backgroundHex.toString());
    }
    return fontColorContrast(backgroundHex, 0.72) === '#000000' ? 'black' : 'white'
};