import useMousePosition from "hooks/useMousePosition";
import React, { useEffect } from "react";

import './Background.sass';

const Background = ({className = ''}) => {
	const [showBackground, setShowBackground] = React.useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShowBackground(true);
		}, 1000);
	}, []);

    const { clientX, clientY } = useMousePosition();

    return showBackground ? <div className={'background dark:bg-gray-800 ' + className}>
        <div className="background__cursor hidden lg:block absolute opacity-20 bg-radial-gradient from-primary via-transparent to-transparent rounded-full h-96 w-96 transform -translate-x-1/2 -translate-y-1/2" style={{ left: clientX + 'px', top: clientY + 'px' }}/>
        <img
            className="background__image absolute select-none pointer-events-none h-full object-contain object-left-bottom top-0 transform -scale-y-100 lg:bottom-0 lg:scale-100"
            alt=""
            src="assets/section-personal-background.webp" />
    </div> : <></>;
};

export default Background;