import React from 'react';
import Background from './components/background/Background';
import './App.sass';
import Intro from './components/intro/Intro';
import Content from './components/content/Content';

function App() {
	return (
		<main className='w-full h-full overflow-hidden'>
			<Background />
			<Content />
			<Intro />
		</main>
	);
}

export default App;
