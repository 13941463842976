export const portfolio = [
    {
        'title': 'Klokhuis Scanner',
        'subtitle': 'Kan jij ze allemaal verzamelen?',
        'type': 'static',
        'description':
            'Wist je dat je in huis triljoenen onzichtbare huisdieren hebt? Maar wie zijn dat? Mogen ze blijven? Voor NPO Innovatie deed ik onderzoek naar het inzetten van WebAR om verhalen voor de jeugd meer impact te geven. Met de Klokhuis Scanner ontmoet je wel 10 van jouw onzichtbare huisdieren.',
        'grade': '10',
        'icon': 'assets/klokhuis-scanner.webp',
        'images': [
            'assets/klokhuisscanner-beerdiertje.webp',
            'assets/klokhuisscanner-giardia.webp',
            'assets/klokhuisscanner-huidbacterie.webp',
            'assets/klokhuisscanner-huisstofmijt.webp',
            'assets/klokhuisscanner-penseelschimmel.webp',
        ],
        'date': [2021, 10, 0],
        'tags': [
            'Augmented Reality',
            'Educatief',
            'Website',
            'Het Klokhuis',
            'React',
            'AI',
        ],
        'links': [
            {
                'title': 'Klokhuis Scanner',
                'url': 'https://www.hetklokhuis.nl/klokhuisscanner',
            },
            {
                'title': 'NPO Innovatie',
                'url': 'https://innovatie.npo.nl/projecten/online-verhalen-versterken-met-webar-onzichtbare-huisdieren',
            },
            {
                'title': 'LinkedIn post',
                'url': 'https://www.linkedin.com/feed/update/urn:li:activity:6850726256124669952/',
            },
        ],
    },
    {
        'title': 'Verrekijker Rijksmuseum Boerhaave',
        'subtitle': 'Rijksmueum Boerhaave in Leiden',
        'type': 'static',
        'description': 'Met dit prototype onderzochten we de mogelijkheden van AR in een speeltuinobject voor kinderen. Door de verrekijker heen zie je een extra laag over de binnentuin. Met deze elementen kan je interacteren met de hendel onder de kijker. Het studieproject is samen met Maisie Klaassen, Marlène Zwetsloot en Irene de Koning ontwikkeld.',
        'grade': '8,9',
        'icon': 'assets/boerhaave.webp',
        'images': [
            'assets/verrekijker-1.webp',
            'assets/verrekijker-2.webp',
            'assets/verrekijker-3.webp',
        ],
        'date': [2022, 1, 0],
        'tags': [
            'Augmented Reality',
            'Educatief',
            'Hardware',
            'Godot',
        ],
        'links': [
            {
                'title': 'Waterland Boerhaave',
                'url': 'https://rijksmuseumboerhaave.nl/te-zien-te-doen/waterland-boerhaave/',
            }
        ],
    },
    {
        'title': 'NPO Kennis Toegankelijkheid',
        'subtitle': 'NPO Kennis... En je weet het!',
        'type': 'link',
        'description':
            'Bij NTR help ik om de toegankelijkheid te verbeteren van componenten op de website van NPO Kennis. Ik analyseer knelpunten en implementeer oplossingen die helpen om de website toegankelijk te maken voor mensen met een beperking, zoals visuele handicaps.',
        'icon': 'assets/npokennis.webp',
        'images': null,
        'date': [2021, 10, 0],
        'tags': [
            'React',
            'Website',
            'Toegankelijkheid',
        ],
        'links': [
            {
                'title': 'NPO Kennis',
                'url': 'https://www.npokennis.nl/',
            },
        ],
    },
    {
        'title': 'Saibo\'s Lab 2',
        'subtitle': 'Jouw vrolijke virtuele docent!',
        'type': 'link',
        'description':
            'Saibo is de super behulpzame virtuele docent die jou helpt met de regels voor labveiligheid. Om het onderwijs te verrijken ontwikkelden we in dienst van de Hogeschool Leiden een virtual reality app voor de opleiding Biologie en Medisch labonderzoek. De app zal ook in gebruik worden genomen door mboRijnland en middelbare scholen. In samenwerking met Marlène Zwetsloot en Kim Hoogland is deze app ontwikkeld.',
        'icon': 'assets/saibo.webp',
        'images': [
            'assets/labveiligheid-1.webp',
            'assets/labveiligheid-2.webp',
            'assets/labveiligheid-3.webp',
            'assets/labveiligheid-4.webp',
        ],
        'date': [2021, 8, 0],
        'tags': [
            'Virtual Reality',
            'Educatief',
            'Website',
            'Android',
            'iOS',
            'Godot',
        ],
        'links': [
            {
                'title': 'Labveiligheid.nl',
                'url': 'https://www.labveiligheid.nl/',
            },
            {
                'title': 'Play Store',
                'url': 'https://play.google.com/store/apps/details?id=nl.labveiligheid',
            },
            {
                'title': 'App Store',
                'url': 'https://apps.apple.com/nl/app/labveiligheid/id1588125934',
            }
        ],
    },
    {
        'title': 'MotionSynth',
        'type': 'link',
        'description':
            'Maak muziek met je handen! MotionSynth is een interactief experiment waarbij je muziek maakt door je handen te bewegen. Probeer het uit met je webcam!',
        'icon': 'assets/motionsynth-icon.webp',
        'images': [
            'assets/motionsynth.gif',
        ],
        'date': [2020, 11, 0],
        'tags': [
            'Website',
            'AI',
            'Muziek',
        ],
        'links': [
            {            
                'title': 'MotionSynth',
                'url': 'https://motionsynth.web.app/',
            },
        ],
    },
    {
        'title': 'Gloves',
        'type': 'static',
        'description':
            'Tijdens dit project hebben Marlène Zwetsloot en ik prototypen handschoenen ontwikkeld die als instrument gebruikt konden worden. Met AI konden eigen gebaren worden getraind die vervolgens met ieder muziekprogramma verbonden konden worden.',
        'grade': '8,1',
        'icon': null,
        'images': [
            'assets/gloves-2.gif',
            'assets/gloves-3.webp',
            'assets/gloves-1.webp',
        ],
        'date': [2020, 11, 0],
        'tags': [
            'Hardware',
            'Muziek',
            'AI',
            'Flutter',
        ],
        'links': [ ],
    },
    {
        'title': 'Laze',
        'subtitle': 'Ontspannen is belangrijk!',
        'type': 'link',
        'description':
            'Laze helpt jou met je productiviteitscyclus. Relaxen is belangrijk, daarom kan Laze je ook nog helpen herrineren om op tijd te stoppen met werken \'s avonds. Gemaakt samen met Maisie Klaassen als schoolproject.',
        'grade': '8,3',
        'icon': 'assets/laze-icon.webp',
        'images': [
            'assets/laze-1.webp',
            'assets/laze-2.webp',
            'assets/laze-3.webp',
        ],
        'date': [2020, 8, 0],
        'tags': [
            'Android',
        ],
        'links': [
            {
                'title': 'Laze',
                'url': 'https://play.google.com/store/apps/details?id=net.collaby.laze',
            },
        ],
    },
    {
        'title': 'Manta',
        'type': 'interactive',
        'description': null,
        'icon': null,
        'date': [2020, 8, 0],
        'tags': [
            'Creative Coding',
            'Website',
        ],
        'links': [
            {
                'title': 'Manta',
                'url': 'art/manta/',
            },
        ],
    },
    {
        'title': 'Tonest',
        'type': 'link',
        'description':
            'Tonest helpt je met oefenen om zuiver te zingen of je instrument te stemmen. Tonest is gemaakt in Flutter en is te downloaden in Google Play. De app komt met history graph en ingebouwde piano (met alle octaven).',
        'icon': null,
        'images': [
            'assets/tonest-1.webp',
            'assets/tonest-2.webp',
            'assets/tonest-3.webp',
        ],
        'date': [2020, 9, 0],
        'tags': [
            'Flutter',
            'Android',
            'Muziek',
        ],
        'links': [
            {
                'title': 'Play Store',
                'url': 'https://play.google.com/store/apps/details?id=net.collaby.tonest',
            },
        ],
    },
    {
        'title': 'PLNT Tiny Offices',
        'type': 'link',
        'description':
            'Met dit project voor TheField kunnen klanten gemakkelijk online een Tiny Office reserveren. De offices zijn gebruiksvriendelijk te managen voor TheField. Gemaakt met Laravel en React. Ontwikkelt als schoolproject in opdracht van TheField.',
        'grade': '10',
        'icon': null,
        'images': [
            'assets/thefield-1.webp',
            'assets/thefield-2.webp',
            'assets/thefield-3.webp',
        ],
        'date': [2020, 7, 0],
        'tags': [
            'Laravel',
            'React',
            'Website',
        ],
        'links': [
            {
                'title': 'Demo',
                'url': 'https://thefield.collaby.net/',
            },
        ],
    },
    {
        'title': 'Karipuna',
        'subtitle': 'Gebaseerd op een waargebeurd verhaal',
        'type': 'link',
        'description':
            'Beleef de sfeer van dit deel van het Amazonewoud, terwijl je leert over de situatie en zelf de loop van het verhaal beïnvloedt. Begeleid André in de beslissingen die hij moet nemen om zijn volk te redden. Een interactief nieuwsverhaal voor een studieproject.',
        'grade': '8',
        'icon': null,
        'images': [
            'assets/karipuna-1.webp',
            'assets/karipuna-2.webp',
            'assets/karipuna-3.webp',
        ],
        'date': [2019, 11, 0],
        'tags': [
            'Educatief',
            'Website',
        ],
        'links': [
            {
                'title': 'Beleef het verhaal',
                'url': 'https://karipuna.web.app/',
            },
        ],
    },
    {
        'title': 'Flock',
        'type': 'interactive',
        'description': 'Interactief experiment. Typ in het vlak om te beginnen en sleep met je muis.',
        'icon': null,
        'date': [2020, 8, 0],
        'tags': [
            'Creative Coding',
            'Website',
        ],
        'links': [
            {
                'title': 'Flocking text',
                'url': 'art/type/',
            },
        ],
    },
    {
        'title': 'Dinoh',
        'type': 'link',
        'description':
            'Speel familiespellen op de tv! Gebruik je Android telefoon om mee te doen en verbind met je Chromecast.',
        'icon': null,
        'images': [
            'assets/dinoh-1.webp',
            'assets/dinoh-2.webp',
            'assets/dinoh-3.webp',
        ],
        'date': [2018, 11, 0],
        'tags': ['Chromecast', 'Android'],
        'links': [
            {
                'title': 'Play Store',
                'url': 'https://play.google.com/store/apps/details?id=nl.birdssoftware.dinoh',
            },
        ],
    },
    {
        'title': 'Mondriaan',
        'type': 'interactive',
        'description': 'Klik er op om een unieke Mondriaan te maken.',
        'icon': null,
        'date': [2020, 8, 0],
        'tags': [
            'Creative Coding',
            'Website',
        ],
        'links': [
            {
                'title': 'Mondriaan',
                'url': 'art/mondriaan/',
            },
        ],
    },
    {
        'title': 'Saibo\'s Lab 1',
        'type': 'link',
        'description':
            'Een virtual reality applicatie voor de opleiding Biologie en Medischlabaratoriumonderzoek. Ontwikkelt met Marlène Zwetsloot en Kim Hoogland als studieproject.',
        'grade': '9,1',
        'icon': null,
        'images': [
            'assets/saibo-1.webp',
            'assets/saibo-2.webp',
        ],
        'date': [2020, 2, 0],
        'tags': [
            'Virtual Reality',
            'Educatief',
            'Website',
        ],
        'links': [
            {
                'title': 'Prototype',
                'url': 'https://saibolab.web.app/',
            },
        ],
    },
    {
        'title': 'VR Pixel Paint',
        'type': 'link',
        'description':
            'Een virtual reality app gemaakt met A-Frame. Werkt op Google Cardboard. Je kan pixel art maken en zelfs kleuren mengen!',
        'icon': null,
        'images': [
            'assets/vrdraw-1.webp',
            'assets/vrdraw-2.webp',
            'assets/vrdraw-3.webp',
        ],
        'date': [2019, 11, 0],
        'tags': [
            'Virtual Reality',
            'Website',
        ],
        'links': [
            {
                'title': 'Uitproberen',
                'url': 'https://tijmen-vr.web.app/draw/',
            }
        ],
    },
    {
        'title': 'VR UFO',
        'type': 'link',
        'description':
            'Laat een UFO landen in Virtual Reality. Gemaakt in A-Frame voor Google Cardboard.',
        'icon': null,
        'images': [
            'assets/ufo-1.webp',
            'assets/ufo-2.webp',
            'assets/ufo-3.webp',
        ],
        'date': [2019, 11, 0],
        'tags': ['Virtual Reality'],
        'links': [
            {
                'title': 'Uitproberen',
                'url': 'https://tijmen-vr.web.app/ufo/',
            }
        ],
    },
    {
        'title': 'MuseBlocks',
        'type': 'static',
        'description':
            'Iedereen kan muziek maken met de blocks in MuseBlocks. Gemaakt als schoolproject, maar nooit gepubliceerd. Neem contact op om meer te weten te komen!',
        'icon': 'assets/museblocks-icon.webp',
        'images': null,
        'date': [2019, 11, 0],
        'tags': [
            'Flutter',
            'Muziek',
        ],
        'links': [],
    },
    {
        'title': 'Roasted Mirchi',
        'type': 'link',
        'description':
            'De website voor production house Roasted Mirchi uit Mumbai. Roasted Mirchi fotografeert en produceert ook muziek.',
        'icon': null,
        'images': [
            'assets/roasted-mirchi.webp',
            'assets/roasted-mirchi-2.webp',
        ],
        'date': [2019, 5, 0],
        'tags': ['Website'],
        'links': [
            {
                'title': 'Website',
                'url': 'http://roastedmirchi.com/',
            }
        ],
    },
    {
        'title': 'Ethereal',
        'description': 'Watch faces voor Wear OS smartwatches. Niet langer beschikbaar in de Play Store.',
        'icon': null,
        'images': [
            'assets/ethereal-1.webp',
            'assets/ethereal-2.webp',
            'assets/ethereal-3.webp',
            'assets/ethereal-4.webp',
            'assets/ethereal-5.webp',
        ],
        'date': [2018, 6, 0],
        'tags': ['Wear OS', 'Android'],
        'links': [],
    },
];