import React from "react";
import Icon from '@mdi/react';
import { mdiArrowDown } from '@mdi/js';
import { timeline } from "resources/timeline";

import './Personal.sass';

const Personal = () => {
    const [isBiographyExpanded, setIsBiographyExpanded] = React.useState(false);

    return <section className="portfolio__section portfolio__section--personal overflow-visible flex flex-col lg:flex-col 3xl:flex-row items-start mb-8 md:mb-12 lg:mb-0 lg:mt-12">
        <button className="text-left p-0 m-0 w-full md:max-w-xl mx-auto lg:mx-0" onClick={() => { setIsBiographyExpanded(!isBiographyExpanded); }} aria-pressed={isBiographyExpanded} aria-label="Meer over mij">
            <article className={'z-10 personal overflow-visible relative shadow-2xl transition-all md:rounded-2xl md:rounded-tr-5xl rounded-tr-5xl p-4 pb-8 lg:pb-4 lg:p-6 w-full lg:pr-12 cursor-pointer select-none bg-white bg-opacity-20 dark:bg-transparent-500 lg:bg-opacity-100 lg:bg-gradient-to-br from-primary to-blue-500 lg:text-white dark:text-white transform active:scale-95 ' + (isBiographyExpanded ? 'pb-12 lg:pb-8' : '')}>
                <img className="personal__photo personal__photo--1 transition-all lg:float-right w-full aspect-square max-w-xxxs md:max-w-xxs shadow-lg rounded-full transform -translate-y-10 -mb-8 lg:translate-x-16 lg:-ml-16" src="assets/personal-3.webp" alt="Foto van mij!" style={{ shapeOutside: 'ellipse()'}} />
                <h1 className="block lg:inline-block text-4xl lg:text-5xl xl:text-6xl font-extrabold pb-4 font-outfit">Tijmen <br className="hidden lg:inline-block" />Ennik<span className={'absolute block text-base font-normal transition-all transform ' + (isBiographyExpanded ? 'opacity-70 max-h-4' : 'max-h-0 opacity-0 select-none -translate-y-2')}>die/hen/hun</span></h1>
                
                <p className={'transition-all ' + (isBiographyExpanded ? 'pt-4' : '')}>
                    Hoi, ik ben Tijmen! Sinds mijn 12de ben ik al bezig met toepassingen ontwikkelen die mensen helpen doelen te bereiken op een zo intuïtief mogelijke manier. Met mijn vaardigheden creëer ik interactieve belevingen van concept tot realisatie. De eindgebruiker staat in dit hele proces centraal.
                    <span className={'block lg:hidden transition-all ' + (isBiographyExpanded ? 'h-0' : 'h-8')}/>
                    <span
                        className={'personal__buttonexpand absolute bottom-4 right-4 inline-block p-2 rounded-full transition-all select-none  hover:shadow-md bg-primary text-white lg:bg-white lg:text-blue-500'}>
                        <Icon path={mdiArrowDown} size={0.9} className={'transform block transition-all text-xl ' + (isBiographyExpanded ? '-rotate-180' : '')} />
                    </span>
                </p>            
                <div className={'relative overflow-visible transition-all duration-500 lg:text-white select-none ' + (isBiographyExpanded ? 'opacity-100 lg:mt-4' : 'transform opacity-0 scale-0')} style={{
                    maxHeight: isBiographyExpanded ? '600px' : '0px',
                }}>
                    <p className="pt-4 lg:pt-0">
                    De middelbare school heb ik afgerond met een 10 voor mijn profielwerkstuk over interactie-ontwerp in software. Daar was ik ook onderwijsassistent bij de Game Design lessen, wat ik super leuk vond!
                    </p>
                    <div className="flex flex-row items-start my-4 lg:my-0">
                        <div className={`personal__photo__container flex-shrink-0 filter drop-shadow-lg hidden lg:block max-w-32 2xl:max-w-96 aspect-square transition-all duration-300 float-left transform lg:-mr-8 2xl:-mr-12 ${(isBiographyExpanded ? 'scale-100 lg:-translate-x-14 2xl:-translate-x-16 delay-200 mt-4' : 'scale-0 hidden opacity-0')}`}>
                            <img className={`personal__photo personal__photo--2 w-full h-full max-w-xxs rounded-full transition-all transform ${isBiographyExpanded ? 'scale-100' : 'scale-0'}`} src="assets/personal-4.webp" alt="Nog een foto van mij!" />
                            {isBiographyExpanded && <img className="personal__photo personal__photo--3 absolute top-0 w-full h-full max-w-xxs transition-all opacity-0 transform scale-50 -rotate-45 duration-200" src="assets/personal-5.webp" alt="Nog een foto van mij!" />}
                            {isBiographyExpanded && <svg width="0" height="0">
                                <clipPath id="personal__photo--2__clip" clipPathUnits="objectBoundingBox">
                                    <path d="M0.715179 0.108855C0.68114 0.108855 0.648495 0.0953335 0.624426 0.0712643L0.590753 0.0375912C0.540632 -0.0125304 0.459368 -0.0125304 0.409247 0.0375912L0.375574 0.0712643C0.351505 0.0953335 0.31886 0.108855 0.284821 0.108855H0.2372C0.166317 0.108855 0.108855 0.166317 0.108855 0.2372V0.284821C0.108855 0.31886 0.0953335 0.351505 0.0712643 0.375574L0.0375912 0.409247C-0.0125304 0.459368 -0.0125304 0.540632 0.0375911 0.590753L0.0712643 0.624426C0.0953335 0.648495 0.108855 0.68114 0.108855 0.715179V0.7628C0.108855 0.833683 0.166317 0.891145 0.2372 0.891145H0.284821C0.31886 0.891145 0.351505 0.904667 0.375574 0.928736L0.409247 0.962409C0.459368 1.01253 0.540632 1.01253 0.590753 0.962409L0.624426 0.928736C0.648495 0.904667 0.68114 0.891145 0.715179 0.891145H0.7628C0.833683 0.891145 0.891145 0.833683 0.891145 0.7628V0.715179C0.891145 0.68114 0.904666 0.648495 0.928736 0.624426L0.962409 0.590753C1.01253 0.540632 1.01253 0.459369 0.962409 0.409247L0.928736 0.375574C0.904666 0.351505 0.891145 0.31886 0.891145 0.284821V0.2372C0.891145 0.166317 0.833683 0.108855 0.7628 0.108855H0.715179Z"></path>
                                </clipPath>
                            </svg>}
                        </div>                    
                        <p className={'inline-block transition-all duration-300 transform lg:mt-4 ' + (isBiographyExpanded ? 'scale-100 delay-300' : 'scale-0 opacity-0')}>
                            Nu studeer ik af in interactie-technologie op de Hogeschool Leiden en werk ik bij NTR voor o.a. Het Klokhuis! Ook maak ik muziek en games als hobby. De minor die ik bij mijn studie heb gevolgd is Robotica, Domotica en Automatisering. In dienst van de Hogeschool ontwikkelden we ook een virtual reality app.
                        </p>                    
                    </div>
                </div>
            </article>
        </button>
        <article className={'timeline transition-all max-w-xl 3xl:max-w-none 3xl:ml-12 p-4 rounded-2xl 3xl:static transform select-none dark:text-white ' + (isBiographyExpanded ? 'block static scale-100 delay-500' : 'hidden lg:hidden xl:hidden 2xl:hidden 3xl:block scale-0 opacity-0 3xl:scale-100 3xl:opacity-100')}>
            {timeline.map((item, index) => {
                return <div key={index} className="timeline__organisation transition-all flex flex-row gap-2 3xl:gap-8">
                    <div className="timeline__organisation__logo flex flex-col items-center flex-grow-0 w-16">
                        <img className="transition-all aspect-square rounded-full h-16 w-16 object-cover inline-block shadow-md" src={item.icon} alt={item.organisation + ' logo'} />     
                        {item.functions.length > 1 && <div className="w-1 mt-2 mb-4 rounded-full bg-black dark:bg-white bg-opacity-10 flex-grow" />}
                    </div>
                    <div className="flex-grow">
                        {item.functions.map((job) => {
                            return <div key={job.title}>
                                <h1 className="text-lg mt-2 font-bold text-gray-900 dark:text-white text-opacity-60">{job.title}</h1>
                                <p className="hidden 2xl:block">{job.description}</p>
                                <p className="text-sm text-gray-900 dark:text-white text-opacity-60 mb-4">{getMonthName(new Date(...job.start))} {job.start[0]} - {job.end ? getMonthName(new Date(...job.end)) + ' ' + job.end[0] : 'heden'}, {getMonthsBetween(new Date(...job.start), job.end ? new Date(...job.end) : new Date())} maanden</p>
                            </div>;
                        })}
                    </div>         
                </div>
            })}
            {/* <a className="timeline__button--linkedin absolute transition-all duration-300 hidden 3xl:flex w-min opacity-0 -mt-2 font-bold bg-blue-500 text-white py-2 px-4 shadow-lg rounded-full h-12 flex-row items-center justify-center" href="//linkedin.com/in/tijmenennik" rel="noreferrer noopener" target="_blank" aria-label="LinkedIn">LinkedIn</a> */}
        </article>
    </section>;
}

const getMonthsBetween = (dateFrom, dateTo) =>  dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))

const getMonthName = (date) => date.toLocaleString('default', { month: 'long' });


export default Personal;