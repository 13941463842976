/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Logo from "./logo/Logo";
import Icon from '@mdi/react';
import { mdiAt, mdiInstagram, mdiLinkedin } from '@mdi/js';

import './Menu.sass';

const Menu = () => {
    return <header className="menu p-4 pb-10 md:p-8 lg:p-10 xl:p-12 flex flex-col lg:justify-end transition-all">
        <Logo />
        <p className="menu__subtitle pb-1 lg:pb-2 text-white text-3xl font-medium font-outfit">creating human-centered interactive experiences</p>
        <nav className="menu__icons">
            <a className="text-4xl lg:text-5xl" href="mailto:tijmenennik@outlook.com" rel="noreferrer noopener" target="_blank" aria-label="E-mail">
                <Icon path={mdiAt} size={2} />
            </a>
            {/* <a className="text-4xl lg:text-5xl mdi mdi-dribbble" href="//dribbble.com/tijmenennik" rel="noreferrer noopener" target="_blank" aria-label="Dribbble">
                <Icon path={mdiAt} size={2} />
            </a> */}
            <a className="text-4xl lg:text-5xl mdi mdi-instagram" href="//instagram.com/tijmenennik" rel="noreferrer noopener" target="_blank" aria-label="Instagram">
                <Icon path={mdiInstagram} size={2} />
            </a>
            {/* <a className="text-4xl lg:text-5xl mdi mdi-telegram" href="//telegram.me/tijmenennik" rel="noreferrer noopener" target="_blank" aria-label="Telegram">
                <Icon path={mdiAt} size={2} />
            </a> */}
            <a className="text-4xl lg:text-5xl mdi mdi-linkedin" href="//linkedin.com/in/tijmenennik" rel="noreferrer noopener" target="_blank" aria-label="LinkedIn">
                <Icon path={mdiLinkedin} size={2} />
            </a>
        </nav>
</header>;
};

export default Menu;