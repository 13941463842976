import React from "react";
import Menu from "./menu/Menu";
import Portfolio from "./portfolio/Portfolio";

const Content = () => {
    return <div className="absolute h-full w-full flex flex-col lg:flex-row overflow-y-auto">
        <Menu />
        <Portfolio />
    </div>;
};

export default Content;