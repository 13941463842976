import Card from "components/card/Card";
import React, { useEffect, useState, useRef } from "react";
import Masonry from "react-smart-masonry";
import { portfolio } from "resources/portfolio";
import Personal from "./personal/Personal";
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

import './Portfolio.sass';

const tryGetFilteringTags = () => {
    if (new URLSearchParams(window.location.search).has('filter')) {
        return new URLSearchParams(window.location.search).get('filter').split(',');
    }
    return [];
};

const Portfolio = () => {
    const [filteringTags, setFilteringTags] = useState(tryGetFilteringTags());
    const [showImages, setShowImages] = useState(false);
    const tagsRef = useRef(null);

    let firstCardElement = null;

    useEffect(() => {
        setTimeout(() => {
            setShowImages(true);
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf('#') === -1) window.history.replaceState({}, document.title, filteringTags.length > 0 ? '?filter=' + filteringTags.join(',') : '/');
        }, 50);
    }, [filteringTags]);

    let allTags = new Set(['2022', '2021', '2020', '2019', '2018']);

    portfolio.forEach(item => {
        item.tags.forEach(tag => {
            allTags.add(tag);
        });
    });

    let portfolioItems = portfolio.filter((item) => {
        if (filteringTags.length === 0) return true;
        // @ts-ignore If it contains a year
        if (filteringTags.some(filteringTag => !isNaN(filteringTag))) {
            return item.tags.some(tag => filteringTags.includes(tag)) || filteringTags.includes(item.date[0].toString());
        } else {
            return item.tags.some(tag => filteringTags.includes(tag))
        }
    }).map((item, index) => {
        return <Card
            onRefReady={(element) => {
                if (index === 0) firstCardElement = element;
            }}
            key={index + item.title}
            title={item.title}
            subtitle={item.subtitle}
            description={item.description}
            grade={item.grade}
            images={item.images}
            links={item.links}
            icon={item.icon}
            type={item.type}
            tags={item.tags}
            filterTags={filteringTags}
            date={item.date}
            index={index}
            showImages={showImages}
            onFilterTagClicked={(tag) => {
                setFilteringTags([tag.toString()]);
                tagsRef.current.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
            }}>
            {item.image}
        </Card>;
    });

    if (filteringTags.length > 0) {
        portfolioItems.push(
            <p key="end" className="py-12 px-8 mx-auto block text-center w-full max-w-xl rounded-2xl border-2 border-transparent-100 dark:text-white dark:border-white dark:border-opacity-20">
                <span className="text-2xl font-bold br">Meer projecten bekijken?</span><br/>
                <span>Projecten zijn gefliterd op {filteringTags.map((tag, index) => <span className="opacity-80" key={tag}>#{tag.toLowerCase()} </span>)}</span><br />
                <button className="mt-4 py-1 px-4 rounded-full bg-primary text-white transition-all transform hover:scale-125 hover:shadow-lg active:scale-110" onClick={() => setFilteringTags([])}>Alles bekijken</button>
            </p>
        );
    }

    return <div className="md:p-4 lg:p-8 2xl:pl-12 w-full lg:overflow-y-auto overflow-x-visible">
        <Personal />
        <section className="portfolio__section portfolio__section--items mb-48 overflow-visible">
            <h1 className="p-2 my-16 hidden lg:block text-6xl font-extrabold whitespace-nowrap overflow-clip overflow-hidden font-outfit dark:text-white">portfolio {filteringTags.map((tag, index) => <span key={index} className="opacity-20 lowercase">#{tag} </span>)}</h1>

            <nav ref={tagsRef} className="relative portfolio__tags px-4 lg:p-0 flex mb-12 flex-row flex-wrap items-start gap-2 max-w-xl lg:max-w-none mx-auto lg:mx-0">
                <button className="absolute py-1 px-4 bg-white z-30 opacity-0 rounded-full border-2 focus:opacity-100 transform scale-0 focus:scale-100 transition-all -top-12 focus:shadow-lg focus:outline-black" onClick={() => firstCardElement && firstCardElement.focus()}>Direct naar projecten en sla filters over</button>
                <button
                    className="portfolio__tag lowercaseborder-transparent-200 bg-primary border-transparent-150 text-white p-1 flex flex-row items-center rounded-full transition-all select-none hover:bg-primary hover:border-white hover:border-opacity-20 hover:text-white hover:shadow-md transform duration-300 hover:scale-125 active:scale-110"
                    aria-label="Filters verwijderen"
                    aria-hidden={filteringTags.length < 1}
                    tabIndex={filteringTags.length < 1 ? -1 : 0}
                    onClick={() => {
                        firstCardElement && firstCardElement.focus();
                        setFilteringTags([]);
                    }}
                    style={{
                        opacity: filteringTags.length > 0 ? '' : '0',
                        marginRight: filteringTags.length > 0 ? '' : '-2.75rem',
                    }}>
                    <Icon path={mdiArrowLeft} size={1} />
                </button>
                {Array.from(allTags).map((tag, index) =>
                    <button
                        key={index}
                        className={'portfolio__tag lowercase py-1 px-4 flex flex-row items-center border-transparent-150 dark:text-white rounded-full transition-all select-none hover:shadow-md transform hover:scale-105 active:scale-90 ' + (filteringTags.includes(tag) ? 'bg-primary border-transparent-150 text-white shadow-md' : 'border-transparent-150 hover:border-primary hover:text-primary-700 dark:hover:text-primary-300 dark:border-white dark:border-opacity-10 dark:hover:bg-transparent hover:bg-primary-100 hover:bg-opacity-30')}
                        aria-pressed={filteringTags.includes(tag)}
                        aria-label={`Tag ${tag} zoeken in mijn projecten`}
                        onClick={() => {
                            setFilteringTags(filteringTags.includes(tag) ? filteringTags.filter(item => item !== tag) : [tag, ...filteringTags]);
                        }}>
                        {tag}
                        <div className={'inline-block bg-white transition-all rounded-full ' + (filteringTags.includes(tag) ? 'h-2 w-2 ml-2 filter blur-xs' : 'h-0 w-0')} />
                    </button>)}

                {filteringTags.length > 0 && <h1 className="p-4 pb-0 lg:hidden text-3xl font-extrabold block w-full">{filteringTags.map((tag, index) => <span key={index} className="opacity-20 lowercase dark:text-white">#{tag} </span>)}</h1>}
            </nav>


            <Masonry
                breakpoints={{
                    'sm': 640,
                    'md': 768,
                    'lg': 1024,
                    'xl': 1280,
                    '2xl': 1536,
                    '3xl': 1900,
                    '4xl': 2200,
                }}
                columns={{
                    'sm': 1,
                    'md': 1,
                    'lg': 1,
                    'xl': 1,
                    '2xl': 2,
                    '3xl': 3,
                    '4xl': 4,
                }}
                gap={48}>
                {portfolioItems}
            </Masonry>
        </section>
    </div>;
};

export default Portfolio;